import { axiosInstance } from "./index";

let handlerEnabled = true;

const getPreofferData = async (preoffer_email_id) =>
  await axiosInstance.get(
    `/candidate/preoffer_emails/${preoffer_email_id}/attachments`,
    {
      handlerEnabled,
    }
  );
const submitPreofferData = async ({
  preoffer_email_id,
  email_attachment_id,
  data,
}) =>
  await axiosInstance.put(
    `/candidate/preoffer_emails/${preoffer_email_id}/attachments/${email_attachment_id}`,
    data,
    {
      handlerEnabled,
    }
  );
const checkPreofferCompleted = async (preoffer_email_id) =>
  await axiosInstance.get(
    `/candidate/preoffer_emails/${preoffer_email_id}/attachments/status`,
    {
      handlerEnabled,
    }
  );

export default {
  getPreofferData,
  submitPreofferData,
  checkPreofferCompleted,
};
