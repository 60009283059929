import * as types from '../types/Snackbar';

const snackbar = (state = {}, action) => {
    switch (action.type) {
      case types.SHOW_SNACKBAR:
        return {
          ...state,
          successSnackbarOpen: true,
          successSnackbarMessage: action.message ,
          snacknarType : action.snacknarType
        };
      case types.HIDE_SNACKBAR:
        return {
          ...state,
          successSnackbarOpen: false,
          errorSnackbarOpen: false,
          infoSnackbarOpen: false
        };
      default:
        return state;
    }
  };

export default snackbar;