import * as types from "../types/Preoffer";

const INITIAL_STATE = {
  preofferData: [],
  isPreofferCompleted: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_PREOFFER_DATA_RECEIVE:
      return { ...state, preofferData: [...action.payload] };
    case types.CHECK_PREOFFER_COMPLETED_RECEIVE:
      return { ...state, isPreofferCompleted: action.payload };
    default:
      return state;
  }
};
