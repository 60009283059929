import { call, put, takeLatest } from "redux-saga/effects";
import * as API from "../../network/apis/MoreInformationQuestions";
import * as ACTIONS from "../actions/MoreInformationQuestions";
import * as TYPE from "../types/MoreInformationQuestions";
import {
  dispatchError,
  getURLParams,
  showSnackbar,
  updateTokenFromUrl,
} from "../../utils/Shared";
import History from "../../routes/History";
import APIPROFILE from  "../../network/apis/Profile";

export function* getMoreInfoRequestQuestions({ payload }) {
  try {
    const result = yield call(APIPROFILE.getCandidateCompanyRequest);
    result.data?.data.profile_type === 3 && updateTokenFromUrl();
    const response = yield call(API.getMoreInfoRequestQuestions, payload);
    yield put(ACTIONS.receiveGetMoreInfoQuestions(response.data.data));
  } catch (err) {
    yield put(
      ACTIONS.receiveGetMoreInfoQuestions({
        error: true,
        data:
          err.response.data &&
          err.response.data.error &&
          err.response.data.error.code
            ? err.response.data.error.code
            : "",
      })
    );
  }
}

export function* saveMoreInfoRequestQuestionsText({ payload }) {
  try {
    const result = yield call(APIPROFILE.getCandidateCompanyRequest);
    result.data?.data.profile_type === 3 && updateTokenFromUrl();
    yield call(API.saveMoreInfoRequestQuestionsTypeText, payload);
    showSnackbar("successFormSubmit");
    History.push("/");
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* saveMoreInfoRequestQuestionsVideo({ payload }) {
  try {
    const result = yield call(APIPROFILE.getCandidateCompanyRequest);
    result.data?.data.profile_type === 3 && updateTokenFromUrl();
    yield call(API.saveMoreInfoRequestQuestionsTypeVideo, payload);
    showSnackbar("done");
    History.push("/");
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* changeExpirationDate({ payload }) {
  try {
    const result = yield call(APIPROFILE.getCandidateCompanyRequest);
    result.data?.data.profile_type === 3 && updateTokenFromUrl();
    yield call(API.changeExpirationDate, payload);
    yield put(ACTIONS.requestGetMoreInfoQuestions(getURLParams("id")));
    showSnackbar("done");
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* MoreInformationQuestionsSagasWatch() {
  yield takeLatest(
    TYPE.REQUEST_GET_MORE_INFO_QUESTIONS,
    getMoreInfoRequestQuestions
  );
  yield takeLatest(
    TYPE.REQUEST_SAVE_MORE_INFO_QUESTIONS_TEXT,
    saveMoreInfoRequestQuestionsText
  );
  yield takeLatest(
    TYPE.REQUEST_SAVE_MORE_INFO_QUESTIONS_VIDEO,
    saveMoreInfoRequestQuestionsVideo
  );
  yield takeLatest(TYPE.REQUEST_CHANGE_EXPIRATION_DATE, changeExpirationDate);
}
