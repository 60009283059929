import * as types from "../types/MyApplications";

const INITIAL_STATE = {
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.MY_APPLICATIONS_RECIEVE:
      return {
            ... action.payload,
      };
    default:
      return state;
  }
};
