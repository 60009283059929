import { axiosInstance } from "./index";

export const getMoreInfoRequestQuestions = async (more_info_request_id) =>
  await axiosInstance.get(
    `/candidate/more_info_request/${more_info_request_id}`
  );

export const saveMoreInfoRequestQuestionsTypeText = async ({
  more_info_request_id,
  data,
}) =>
  await axiosInstance.post(
    `/candidate/more_info_request/${more_info_request_id}/text`,
    data
  );

export const saveMoreInfoRequestQuestionsTypeVideo = async ({
  more_info_request_id,
  data,
}) => {
  
  await axiosInstance.post(
    `/candidate/more_info_request/${more_info_request_id}/videos`,
    data,
  )
};

export const changeExpirationDate = async ({ more_info_request_id, data }) =>
  await axiosInstance.put(
    `/candidate/more_info_request/ChangeRequestExpiry/${more_info_request_id}`,
    data
  );
  