import store from "../../store";
import { loader } from "../../store/actions/Loader";
import Auth from "../../utils/Auth";
import History from "./../../routes/History";
import Cookies from "js-cookie";
import { getEnv, authRedirection } from "../../utils/redirection";

export const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
    ? false
    : true;
};
export const isLoaderShouldDisabled = (config = {}) => {
  return config.hasOwnProperty("disableLoader") ? true : false;
};

export const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    if (!isLoaderShouldDisabled(request)) {
      !request.url.includes("latest") && document.body.classList.add("loading-indicator");
      !request.url.includes("latest") && store.dispatch(loader(true));
    }
    const token = Cookies.get("token", { domain: getEnv() });

    request.headers["Accept-Language"] =
      localStorage.getItem("lang") === "ar" ? "ar-SA" : "en-US";
    request.headers["Content-Type"] = "application/json";
    if (token) {
      request.headers["Authorization"] = `Bearer ${token}`;
    }
    request.headers["domain"] = localStorage.getItem("domain");
  }
  return request;
};

export const successHandler = (response) => {
  if (isHandlerEnabled(response)) {
    !response.request.responseURL.includes("latest") && store.dispatch(loader(false));
    !response.request.responseURL.includes("latest") && document.body.classList.remove("loading-indicator");
  }
  return response;
};

export const errorHandler = (error) => {
  if (isHandlerEnabled(error.config)) {
    store.dispatch(loader(false));
    document.body.classList.remove("loading-indicator");
    if (error.response.status === 401) {
      localStorage.setItem(
        "prevPath",
        `${History.location.pathname}${History.location.search}`
      );
      Auth.signOut();
      window.location.href = authRedirection("login");
    }
    if (error.response.status === 409) {
      Auth.signOut();
      History.push("/deactivated");
    }
  }
  return Promise.reject({ ...error });
};
