import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../network/apis/Offer";
import * as ACTIONS from "../actions/Offer";
import * as TYPE from "../types/Offer";
import {
  dispatchError,
  showSnackbar,
  updateTokenFromUrl,
} from "../../utils/Shared";
import History from "../../routes/History";
import APIPROFILE from "../../network/apis/Profile";
export function* getOfferData({ payload }) {
  try {
    const result = yield call(APIPROFILE.getCandidateCompanyRequest);
    result.data?.data.profile_type === 3 && updateTokenFromUrl();
    const response = yield call(API.getOffertemplate, payload);
    yield put(ACTIONS.getOfferDataReceive(response.data.data));
  } catch (err) {
    yield put(ACTIONS.getOfferDataReceive(err?.response?.data));
  }
}
export function* uploadSignature({ payload }) {
  try {
    const result = yield call(APIPROFILE.getCandidateCompanyRequest);
    result.data?.data.profile_type === 3 && updateTokenFromUrl();
    const response = yield call(API.uploadCandidateSignature, payload);
    yield put(ACTIONS.uploadCandidateSignatureReceive(response.data.data));
  } catch (err) {
    console.log(err);
  }
}
export function* approveOffer({ payload }) {
  try {
    const result = yield call(APIPROFILE.getCandidateCompanyRequest);
    result.data?.data.profile_type === 3 && updateTokenFromUrl();
    yield call(API.acceptOffer, payload);
    showSnackbar("successApprovedOffer");
    History.push("/");
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* rejectOffer({ payload }) {
  try {
    yield call(API.rejectOffer, payload);
    showSnackbar("done");
    History.push("/");
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* reviewOffer({ payload }) {
  try {
    yield call(API.reviewOffer, payload);
    showSnackbar("done");
    History.push("/");
  } catch (err) {
    dispatchError(err.response.data);
  }
}
export function* downloadFile({ payload }) {
  try {
    //business rule force us to handle download file like this..
    const response = yield call(API.downloadFile, payload);
    /* Download File */
    let BLOB = new Blob([response.data], { type: "application/pdf" });
    let url = window.URL.createObjectURL(BLOB);
    let link = document.createElement("a");
    link.style.visibility = "hidden";
    link.href = url;
    link.setAttribute("download", payload.fileName);
    document.body.appendChild(link);
    link.click();
    /* Download File */
  } catch (err) {
    dispatchError(err.response.data);
  }
}
export function* OfferSagasWatch() {
  yield takeLatest(TYPE.GET_OFFER_TEMPLATE_REQUEST, getOfferData);
  yield takeLatest(TYPE.UPLOAD_CANDIDATE_SIGNATURE_REQUEST, uploadSignature);
  yield takeLatest(TYPE.APPROVE_OFFER_REQUEST, approveOffer);
  yield takeLatest(TYPE.REJECT_OFFER_REQUEST, rejectOffer);
  yield takeLatest(TYPE.REVIEW_OFFER_REQUEST, reviewOffer);
  yield takeLatest(TYPE.DOWNLOAD_OFFER_FILE, downloadFile)
}
