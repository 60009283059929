import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../network/apis/Contract";
import * as ACTIONS from "../actions/Contract";
import * as TYPE from "../types/Contract";
import {
  dispatchError,
  showSnackbar,
  updateTokenFromUrl
} from "../../utils/Shared";
import History from "../../routes/History";
import APIPROFILE from "../../network/apis/Profile";
import store from "../../store";
import { getDomain } from "../../utils/redirection";
export function* getContractData({ payload }) {
  try {
    const result = yield call(APIPROFILE.getCandidateCompanyRequest);
    result.data?.data.profile_type === 3 && updateTokenFromUrl();
    const response = yield call(API.getContracttemplate, payload);
    yield put(ACTIONS.getContractDataReceive(response.data.data));
  } catch (err) {
    yield put(ACTIONS.getContractDataReceive(err?.response?.data));
  }
}
export function* uploadSignature({ payload }) {
  try {
    const result = yield call(APIPROFILE.getCandidateCompanyRequest);
    result.data?.data.profile_type === 3 && updateTokenFromUrl();
    const response = yield call(API.uploadCandidateSignature, payload);
    yield put(ACTIONS.uploadCandidateSignatureReceive(response.data.data));
  } catch (err) {
    console.log(err);
  }
}
export function* approveContract({ payload }) {
  try {
    const lang = store.getState().locale.lang;
    const result = yield call(APIPROFILE.getCandidateCompanyRequest);
    result.data?.data.profile_type === 3 && updateTokenFromUrl();
    yield call(API.acceptContract, payload);
    const domain = getDomain();
    const message =
      lang === "ar"
        ? `شكرا لانضمامك إلى فريق ${domain}, نتطلع إلى بدء العمل معك`
        : `${domain} team. We are looking forward to start working with you.`;
    showSnackbar({
      val: message,
    });
    History.push("/");
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* rejectContract({ payload }) {
  try {
    yield call(API.rejectContract, payload);
    showSnackbar("done");
    History.push("/");
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* reviewContract({ payload }) {
  try {
    yield call(API.reviewContract, payload);
    showSnackbar("done");
    History.push("/");
  } catch (err) {
    dispatchError(err.response.data);
  }
}
export function* downloadFile({ payload }) {
  try {
    //business rule force us to handle download file like this..
    const response = yield call(API.downloadFile, payload);
    /* Download File */
    let BLOB = new Blob([response.data], { type: "application/pdf" });
    let url = window.URL.createObjectURL(BLOB);
    let link = document.createElement("a");
    link.style.visibility = "hidden";
    link.href = url;
    link.setAttribute("download", payload.fileName);
    document.body.appendChild(link);
    link.click();
    /* Download File */
  } catch (err) {
    dispatchError(err.response.data);
  }
}
export function* getImageBase64({ payload }) {
  try {
    const response = yield call(API.getImageBase64, payload);
    yield put(ACTIONS.getImageBase64Recieve(response.data.data));
  } catch (err) {
    dispatchError(err.response.data);
  }
}
export function* ContractSagasWatch() {
  yield takeLatest(TYPE.GET_CONTRACT_TEMPLATE_REQUEST, getContractData);
  yield takeLatest(TYPE.UPLOAD_CANDIDATE_SIGNATURE_REQUEST, uploadSignature);
  yield takeLatest(TYPE.APPROVE_CONTRACT_REQUEST, approveContract);
  yield takeLatest(TYPE.REJECT_CONTRACT_REQUEST, rejectContract);
  yield takeLatest(TYPE.REVIEW_CONTRACT_REQUEST, reviewContract);
  yield takeLatest(TYPE.DOWNLOAD_CONTRACT_FILE, downloadFile);
  yield takeLatest(TYPE.GET_IMAGE_64_REQUEST, getImageBase64);
}
