import * as types from "../types/videoInterview";

const INITIAL_STATE = {
  startInterview: false,
  interviewTokens: {},
  isSubscribed: false,
  showInterviewEndModal: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.INTERVIEW_END_MODAL:
      return {
        ...state,
        showInterviewEndModal: action.payload
      }
    case types.START_INTERVIEW_RECEIVE:
      return {
        ...state,
        startInterview: action.payload.startInterview,
        interviewTokens: action.payload.data,
      };
    case types.HANDLE_SUBSCRIPTION:
      return {
        ...state,
        isSubscribed: action.payload,
      };
    default:
      return state;
  }
};
