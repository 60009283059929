import * as types from "../types/Profile";

const INITIAL_STATE = {
  disbaleAdvertQuestions: null,
  candidateCompany: {
    website_url: "",
    plan_name_en: "",
    done: false,
    primary_color: "#00000000",
    secondary_color: "#00000000",
    profile_type: 1
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_PROFILE_RECEIVE:
      return {
        ...state,
        ...action.payload,
      };
    case types.UPLOAD_CV_RECIEVE:
      return {
        ...state,
        ...action.payload,
      };
    case types.UPLOAD_IMAGE_RECIEVE:
      return {
        ...state,
        ...action.payload,
      };
    case types.DISABLE_ADVERT_QUESTIONS_RECEIVE:
      return {
        ...state,
        disbaleAdvertQuestions: action.payload,
      };
    case types.CANDIDATE_COMPANY_RECIEVE:
      return {
        ...state,
        candidateCompany: {
          ...action.payload,
          done: true,
        },
      };
    default:
      return state;
  }
};
