import * as types from "../types/MoreInformationQuestions";

const INITIAL_STATE = {
  questionsInfo: {},
  changeExpiration: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RECEIVE_GET_MORE_INFO_QUESTIONS:
      return {
        ...state,
        questionsInfo: action.payload,
      };
    case types.RECEIVE_CHANGE_EXPIRATION_DATE:
      return {
        ...state,
        changeExpiration: action.payload,
      };
    default:
      return state;
  }
};
