export const GET_CONTRACT_TEMPLATE_REQUEST = 'GET_CONTRACT_TEMPLATE_REQUEST';
export const GET_CONTRACT_TEMPLATE_RECEIVE = 'GET_CONTRACT_TEMPLATE_RECEIVE';

export const UPLOAD_CANDIDATE_SIGNATURE_REQUEST = 'UPLOAD_CANDIDATE_SIGNATURE_REQUEST';
export const UPLOAD_CANDIDATE_SIGNATURE_RECEIVE = 'UPLOAD_CANDIDATE_SIGNATURE_RECEIVE';

export const APPROVE_CONTRACT_REQUEST = 'APPROVE_CONTRACT_REQUEST';
export const APPROVE_CONTRACT_RECEIVE = 'APPROVE_CONTRACT_RECEIVE';

export const REJECT_CONTRACT_REQUEST = 'REJECT_CONTRACT_REQUEST';
export const REJECT_CONTRACT_RECEIVE = 'REJECT_CONTRACT_RECEIVE';

export const REVIEW_CONTRACT_REQUEST = 'REVIEW_CONTRACT_REQUEST';
export const REVIEW_CONTRACT_RECEIVE = 'REVIEW_CONTRACT_RECEIVE';

export const DOWNLOAD_CONTRACT_FILE = 'DOWNLOAD_CONTRACT_FILE';
export const UPLOAD_CONTRACT_FILE = 'UPLOAD_CONTRACT_FILE';

export const GET_IMAGE_64_RECIEVE = 'GET_IMAGE_64_RECIEVE';
export const GET_IMAGE_64_REQUEST = 'GET_IMAGE_64_REQUEST';
