import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../network/apis/ScreeningQuetions";
import * as ACTION from "../actions/ScreeningQuestions";
import * as TYPE from "../types/ScreeningQuestions";
import { dispatchError, showSnackbar } from "../../utils/Shared";
import History from "./../../routes/History";
import ReactGA from "react-ga";

export function* jobScreeningQuestions({ payload }) {
  try {
    const response = yield call(API.jobScreeningQuestions, payload);
    yield put(ACTION.getScreeningQuestionReceive(response.data.data));
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* applyJobRequest({ payload }) {
  let lang = localStorage.getItem("lang");
  try {
    const response = yield call(API.applyJobRequest, payload);
    yield put(ACTION.applyJobReceive(response.data.data));
    showSnackbar("successApply");
    // History.push("/");
    ReactGA.event({
      category: `${localStorage.getItem("domain")} : Apply to jobs `,
      action: "User pressed apply to jobs button",
    });
  } catch (err) {
    if (err.response?.data?.error?.code) {
      if (err.response?.data?.error?.code === 2) {
        History.push(`/screeningQuestions/${payload.job_request_id}`);
        History.push({
          state: {
            code: 2,
            errorMsg: null,
          },
        });
        // dispatchError(err.response);
      } else if (err.response.data.error.code === 1) {
        History.location.code = 1;
        let errorAr =
          err.response.data.error.message +' ('+
          err.response.data.error.requiredfieldsar+')';
        let errorEn =
          err.response.data.error.message +' ('+
          err.response.data.error.requiredfieldsen+')';
        lang === "en"
          ? History.push({
              state: {
                errorMsg: errorEn,
                code: 1,
              },
            })
          : History.push({
              state: {
                errorMsg: errorAr,
                code: 1,
              },
            });
        // dispatchError(err.response.data);
      }
      // else {
      //   History.push({
      //     state: {
      //       code: null,
      //     },
      //   });
      //   console.log(err.response.data);
      // }
    } else {
      History.push({
        state: {
          code: null,
        },
      });
      dispatchError(err.response.data);
    }
  }
}

export function* ScreeningQuestionsSagasWatch() {
  yield takeLatest(
    TYPE.GET_JOB_SCREENING_QUESTIONS_REQUEST,
    jobScreeningQuestions
  );
  yield takeLatest(TYPE.APPLY_JOB_REQUEST, applyJobRequest);
}
