import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../network/apis/Vacancies";
import * as ACTION from "../actions/Vacancies";
import * as TYPE from "../types/Vacancies";
import { dispatchError } from "../../utils/Shared";

export function* vacanyInnerDetailsRequest({ payload }) {
  try {
    const response = yield call(API.vacancyInnerDetailsRequest, payload);
    yield put(ACTION.vacancyInnerDetailsReceive(response.data.data));
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* VacanciesSagasWatch() {
  yield takeLatest(
    TYPE.VACANCY_INNER_DETAILS_REQUEST,
    vacanyInnerDetailsRequest
  );
}
