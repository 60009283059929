import {axiosInstance} from './index';


const signupRequest = async (data) => {
    return await axiosInstance.post('/candidate/signUp', data);
}
const sendEmailAgainRequest = async (data) => {
    return await axiosInstance.post('/candidate/ResendConfirmEmail', data);
}
const confirmEmailRequest = async (data) => {
    return await axiosInstance.post('/candidate/confirmEmail', data);
}
const signinRequest = async (data) => {
    return await axiosInstance.post('/candidate/login', data);
}

const validateDomain = async (domain) => {
    console.log(domain);
    return await axiosInstance.get(`/admin/auth/company/${domain}`);
}

const forgetPasswordRequest = async (data) =>{
    return await axiosInstance.post('/candidate/forget_password' , data)
}

const resetPasswordRequest = async (data) =>{
    return await axiosInstance.post('/candidate/reset_password' , data )
}

export default {
    signupRequest ,
    forgetPasswordRequest,
    sendEmailAgainRequest,
    confirmEmailRequest,
    resetPasswordRequest,
    signinRequest,
    validateDomain
};