import { axiosInstance } from "./index";

let handlerEnabled = true;

const getOffertemplate = async (offer_id) =>
  await axiosInstance.get(`/candidate/offers/${offer_id}`, {
    handlerEnabled,
  });

const uploadCandidateSignature = async (data) =>
  await axiosInstance.post(`/candidate/offers/signature`, data, {
    handlerEnabled,
  });

const acceptOffer = async ({ offer_id, data }) =>
  await axiosInstance.post(`/candidate/offers/${offer_id}/accept`, data, {
    handlerEnabled,
  });

const rejectOffer = async ({ offer_id, data }) =>
  await axiosInstance.post(`/candidate/offers/${offer_id}/reject`, data, {
    handlerEnabled,
  });
const reviewOffer = async ({ offer_id, data }) =>
  await axiosInstance.post(`/candidate/offers/${offer_id}/review`, data, {
    handlerEnabled,
  });
const downloadFile = async (payload) =>
  await axiosInstance.get(
    `/candidate/offers/downloadOffer_AttachFile/${payload.offer_id}`,
    {
      responseType: "arraybuffer",
      disableLoader: true
    },
    handlerEnabled
  );

export default {
  getOffertemplate,
  uploadCandidateSignature,
  acceptOffer,
  rejectOffer,
  reviewOffer,
  downloadFile,
};
