import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../network/apis/Preoffer";
import * as ACTIONS from "../actions/Preoffer";
import * as TYPE from "../types/Preoffer";
import {
  dispatchError,
  updateTokenFromUrl,
  showSnackbar,
} from "../../utils/Shared";
import APIPROFILE from  "../../network/apis/Profile";

export function* getPreofferData({ payload }) {
  try {
    const result = yield call(APIPROFILE.getCandidateCompanyRequest);
    result.data?.data.profile_type === 3 && updateTokenFromUrl();
    const response = yield call(API.getPreofferData, payload);
    yield put(ACTIONS.getPreofferDataReceive(response.data.data));
    yield put(ACTIONS.checkPreofferCompletedReceive(null));
  } catch (err) {
    yield put(ACTIONS.checkPreofferCompletedReceive("expired"));
  }
}
export function* submitPreofferData({ payload }) {
  try {
    const result = yield call(APIPROFILE.getCandidateCompanyRequest);
    result.data?.data.profile_type === 3 && updateTokenFromUrl();
    yield call(API.submitPreofferData, payload);
    showSnackbar("savedSuccessfuly");
    yield put(ACTIONS.checkPreofferCompletedReceive(null));
  } catch (err) {
    dispatchError(err.response.data);
  }
}
export function* checkPreofferCompleted({ payload }) {
  try {
    const result = yield call(APIPROFILE.getCandidateCompanyRequest);
    result.data?.data.profile_type === 3 && updateTokenFromUrl();
    yield call(API.checkPreofferCompleted, payload);
    yield put(ACTIONS.checkPreofferCompletedReceive("completed"));
  } catch (err) {
    yield put(ACTIONS.checkPreofferCompletedReceive("missing"));
  }
}

export function* PreofferSagasWatch() {
  yield takeLatest(TYPE.GET_PREOFFER_DATA_REQUEST, getPreofferData);
  yield takeLatest(TYPE.SUBMIT_PREOFFER_DATA_REQUEST, submitPreofferData);
  yield takeLatest(
    TYPE.CHECK_PREOFFER_COMPLETED_REQUEST,
    checkPreofferCompleted
  );
}
