import { axiosInstance } from "./index";

let handlerEnabled = true;

const updatePersonalInfo = async (data) =>
  await axiosInstance.put(`/candidate/profile/personal_data`, data, {
    handlerEnabled,
  });
const uploadCvReqeust = async (data) =>
  await axiosInstance.put(`/candidate/profile/cv`, data, { handlerEnabled });

const uploadImageReqeust = async (data) =>
  await axiosInstance.put(`/candidate/profile/picture`, data, {
    handlerEnabled,
  });

export const getUserProfile = async () =>
  await axiosInstance.get(`/candidate/profile`, { handlerEnabled });

const saveEducationInfoRequest = async (data) =>
  await axiosInstance.post(`/candidate/profile/education`, data, {
    handlerEnabled,
  });
const deleteEducationInfoRequest = async (id) =>
  await axiosInstance.delete(`/candidate/profile/education/${id}`, {
    handlerEnabled,
  });
const saveWorkExperienceInfoRequest = async (data) =>
  await axiosInstance.post(`/candidate/profile/work_experience`, data, {
    handlerEnabled,
  });
const deleteWorkExperienceInfoRequest = async (id) =>
  await axiosInstance.delete(`/candidate/profile/work_experience/${id}`, {
    handlerEnabled,
  });

const saveSkillsInfoRequest = async (data) =>
  await axiosInstance.put(`/candidate/profile/skills`, data, {
    handlerEnabled,
  });
const saveSimpleProfile = async (data) =>
  await axiosInstance.put(`/candidate/profile/simple_personal_data`, data, {
    handlerEnabled,
  });

const updateAdvertQuestionsRequest = async (data) =>
  await axiosInstance.put(`/candidate/profile/info_questions`, data, {
    handlerEnabled,
  });
const disbaleAdvertQuestions = async () =>
  await axiosInstance.get(`/admin/subscriptions/disable_Advert_questions`, {
    handlerEnabled,
  });

const saveCertificatesLicenses = async (data)=>
await axiosInstance.post(`/candidate/profile/Certificates`, data , {
  handlerEnabled,
});


const deleteCertificatesLicenses = async (id) =>
  await axiosInstance.delete(`/candidate/profile/certification/${id}`, {
    handlerEnabled,
  });

const getCandidateCompanyRequest = async () =>
await axiosInstance.get(`/Company/company_data_by_domain_name`, {
  handlerEnabled,
});
export default {
  uploadCvReqeust,
  uploadImageReqeust,
  getUserProfile,
  saveEducationInfoRequest,
  deleteEducationInfoRequest,
  saveWorkExperienceInfoRequest,
  deleteWorkExperienceInfoRequest,
  updatePersonalInfo,
  saveSkillsInfoRequest,
  saveSimpleProfile,
  updateAdvertQuestionsRequest,
  disbaleAdvertQuestions,
  saveCertificatesLicenses,
  deleteCertificatesLicenses,
  getCandidateCompanyRequest
};
