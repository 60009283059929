import * as types from "../types/UploadCVMode";

const INITIAL_STATE = {
  viewFormUploadCVMode: false,
  reset: false,
  timerExpire: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.UPLOAD_CV_MODE_RECIEVE:
      return { ...state, viewFormUploadCVMode: action.payload };
    case types.VERIFY_OTP_CODE_RECIEVE:
      return { ...state, reset: action.payload };
      case types.TIMER_EXPIRE:
        console.log(action.payload)
        return { ...state, timerExpire: action.payload };
    default:
      return state;
  }
};
