import { combineReducers } from "redux";
import locale from "./Lang";
import auth from "./Auth";
import loader from "./Loader";
import snackbar from "./Snackbar";
import vacancies from "./Vacancies";
import lookups from "./Lookups";
import profile from "./Profile";
import Home from "./Home";
import screeningQuestions from "./ScreeningQuestions";
import myApplications from "./MyApplications";
import videoInterview from "./videoInterview";
import preoffer from "./Preoffer";
import moreInfoQuestions from "./MoreInformationQuestions";
import offer from "./Offer";
import contract from "./Contract";
import cvMode from "./UploadCVMode";
import notification from "../Notification/reducer"
export default combineReducers({
  locale,
  auth,
  loader,
  snackbar,
  vacancies,
  lookups,
  profile,
  Home,
  screeningQuestions,
  myApplications,
  videoInterview,
  preoffer,
  cvMode,
  moreInfoQuestions,
  offer,
  contract,
  notification
});
