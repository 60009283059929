import * as types from "../types/Vacancies";

const INITIAL_STATE = {
  vacancy_inner: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.VACANCY_INNER_DETAILS_RECEIVE:
      return {
        ...state,
        vacancy_inner: action.payload
      };
    default:
      return state;
  }
};
