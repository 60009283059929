import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../network/apis/videoInterview";
import * as ACTION from "../actions/videoInterview";
import * as TYPE from "../types/videoInterview";
import { dispatchError, updateTokenFromUrl } from "../../utils/Shared";
import APIPROFILE from  "../../network/apis/Profile";

export function* startInterviewRequest({ payload }) {
  try {
    const result = yield call(APIPROFILE.getCandidateCompanyRequest);
    result.data?.data.profile_type === 3 && updateTokenFromUrl();
    const response = yield call(API.startInterviewRequest, payload);
    yield put(
      ACTION.startInterviewReceive({
        startInterview: true,
        data: response.data?.data,
      })
    );
  } catch (err) {
    // dispatchError(err.response);
  }
}
export function* interviewDurationRequest({ payload }) {
  try {
    const result = yield call(APIPROFILE.getCandidateCompanyRequest);
    result.data?.data.profile_type === 3 && updateTokenFromUrl();
    yield call(API.interviewDurationRequest, payload);
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* leaveInterviewRequest({ payload }) {
  try {
    const result = yield call(APIPROFILE.getCandidateCompanyRequest);
    result.data?.data.profile_type === 3 && updateTokenFromUrl();
    yield call(API.leaveInterviewRequest, payload);
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* VideoInterviewSagasWatch() {
  yield takeLatest(TYPE.START_INTERVIEW_REQUEST, startInterviewRequest);
  yield takeLatest(TYPE.INTERVIEW_DURATION_REQUEST, interviewDurationRequest);
  yield takeLatest(TYPE.LEAVE_INTERVIEW, leaveInterviewRequest);
}
