import { axiosInstance } from "./index";

let handlerEnabled = true;

const getContracttemplate = async (contract_id) =>
  await axiosInstance.get(`/candidate/contracts/${contract_id}`, {
    handlerEnabled,
  });

const uploadCandidateSignature = async (data) =>
  await axiosInstance.post(`/candidate/contracts/signature`, data, {
    handlerEnabled,
  });

const acceptContract = async ({ data }) =>
  await axiosInstance.post(`/candidate/contracts/accept`, data, {
    handlerEnabled,
  });

const rejectContract = async ({ data }) =>
  await axiosInstance.post(`/candidate/contracts/reject`, data, {
    handlerEnabled,
  });
const reviewContract = async ({ data }) =>
  await axiosInstance.post(`/candidate/contracts/review`, data, {
    handlerEnabled,
  });
const downloadFile = async (payload) =>
  await axiosInstance.get(
    `/candidate/contracts/downloadContract_AttachFile/${payload.contract_id}`,
    {
      responseType: "arraybuffer",
      disableLoader: true
    },
    handlerEnabled
  );
 export const getImageBase64 = async (contract_id) =>
  await axiosInstance.get(`candidate/getCoverLetter/${contract_id}/false`, {
    handlerEnabled,
  });
export default {
  getContracttemplate,
  uploadCandidateSignature,
  acceptContract,
  rejectContract,
  reviewContract,
  downloadFile,
  getImageBase64
};
