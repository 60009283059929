import { axiosInstance } from "../../network/apis";

let handlerEnabled = true;

 const getNotificationPopupRequest = async id => {
    return await axiosInstance.get(`/general/notifications/latest`, { handlerEnabled });
  };

const setNotificationVistedRequest = async id => {
  return await axiosInstance.put(`/general/notifications/${id}/visited`, { handlerEnabled });
};

const notificationListRequest= async params => {
  return await axiosInstance.get(`/general/notifications/`, {
    params,
    handlerEnabled,
  }, { handlerEnabled });
};
const setNotificationSeenRequest= async () => {
  return await axiosInstance.put(`/general/notifications/seen`, { handlerEnabled });
};

export default {
  getNotificationPopupRequest,
  setNotificationVistedRequest,
  notificationListRequest,
  setNotificationSeenRequest
};
