import * as types from "../types/Preoffer";

export const getPreofferDataRequest = (payload) => ({
  type: types.GET_PREOFFER_DATA_REQUEST,
  payload,
});
export const getPreofferDataReceive = (payload) => ({
  type: types.GET_PREOFFER_DATA_RECEIVE,
  payload,
});
export const submitPreofferDataRequest = (payload) => ({
  type: types.SUBMIT_PREOFFER_DATA_REQUEST,
  payload,
});
export const checkPreofferCompletedRequest = (payload) => ({
  type: types.CHECK_PREOFFER_COMPLETED_REQUEST,
  payload,
});
export const checkPreofferCompletedReceive = (payload) => ({
  type: types.CHECK_PREOFFER_COMPLETED_RECEIVE,
  payload,
});
