import * as types from "./types";

const INITIAL_STATE = {
    popupNotification:[],
    notificationList:[]
}

export default (state = INITIAL_STATE , action) => {
    switch (action.type) {
        case types.POPUP_NOTIFICATION_RESPONSE:
            return { ...state, popupNotification:action.payload};
            case types.NOTIFICATION_LIST_RESPONE:
            return { ...state, notificationList:action.payload};
            
       
        default: return state;
    }
}