import { axiosInstance } from "./index";

const OTPValidation = async (data) =>
  await axiosInstance.post(`/candidate/dummy_candidate/confirm_otp`, data);

const ResendOTPValidation = async (data) => 
 await axiosInstance.post(`/candidate/dummy_candidate/resend_otp`, data);

const uploadCVModeRequest = async (data) => 
  await axiosInstance.post(`/candidate/dummy_candidate`, data);

export default {
  OTPValidation,
  uploadCVModeRequest,
  ResendOTPValidation,
};
