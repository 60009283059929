import * as types from "../types/UploadCVMode";
export const uploadCvModeReceive = (payload) => ({
  type: types.UPLOAD_CV_MODE_RECIEVE,
  payload,
});
export const uploadCvModeRequest = (payload) => ({
  type: types.UPLOAD_CV_MODE_REQUEST,
  payload,
});
export const verifyOTPRequest = (payload) => ({
  type: types.VERIFY_OTP_CODE_REQUEST,
  payload,
})
export const verifyOTPRecieve = (payload) => ({
  type: types.VERIFY_OTP_CODE_RECIEVE,
  payload,
})
export const resendOTPRequest = (payload) => ({
  type: types.RESEND_OTP_CODE_REQUEST,
  payload
})
export const resendOTPRecieve = (payload) => ({
  type: types.RESEND_OTP_CODE_RECIEVE,
  payload
})
export const timerOTPExpire = (payload) => ({
  type: types.TIMER_EXPIRE,
  payload
})
