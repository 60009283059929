import * as types from "../types/videoInterview";

export const startInterviewRequest = (payload) => ({
  type: types.START_INTERVIEW_REQUEST,
  payload,
});
export const startInterviewReceive = (payload) => ({
  type: types.START_INTERVIEW_RECEIVE,
  payload,
});
export const handleSubscription = payload => ({
  type: types.HANDLE_SUBSCRIPTION,
  payload
})
export const interviewDurationRequest = payload => ({
  type: types.INTERVIEW_DURATION_REQUEST,
  payload
})
export const toggleInterviewEndModal = (payload) => ({
  type: types.INTERVIEW_END_MODAL,
  payload
})
export const leaveInterviewRequest = (payload) => ({
  type: types.LEAVE_INTERVIEW,
  payload
})
