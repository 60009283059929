import * as types from '../types/ScreeningQuestions';

export const getScreeningQuestionRequest = payload => ({
    type: types.GET_JOB_SCREENING_QUESTIONS_REQUEST,
    payload
})
export const getScreeningQuestionReceive = payload => ({
    type: types.GET_JOB_SCREENING_QUESTIONS_RECEIVE,
    payload
})

export const applyJobRequest = payload => ({
    type: types.APPLY_JOB_REQUEST,
    payload
})
export const applyJobReceive = payload => ({
    type: types.APPLY_JOB_RECEIVE,
    payload
})