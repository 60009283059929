import * as types from "../types/Contract";

export const getContractDataRequest = (payload) => ({
  type: types.GET_CONTRACT_TEMPLATE_REQUEST,
  payload,
});
export const getContractDataReceive = (payload) => ({
  type: types.GET_CONTRACT_TEMPLATE_RECEIVE,
  payload,
});
export const uploadCandidateSignatureRequest = (payload) => ({
  type: types.UPLOAD_CANDIDATE_SIGNATURE_REQUEST,
  payload,
});
export const uploadCandidateSignatureReceive = (payload) => ({
  type: types.UPLOAD_CANDIDATE_SIGNATURE_RECEIVE,
  payload,
});
export const acceptContractRequest = (payload) => ({
  type: types.APPROVE_CONTRACT_REQUEST,
  payload,
});
export const rejectContractRequest = (payload) => ({
  type: types.REJECT_CONTRACT_REQUEST,
  payload,
});
export const reviewContractRequest = (payload) => ({
  type: types.REVIEW_CONTRACT_REQUEST,
  payload,
});
export const downloadPDFFile = (payload) => ({
  type: types.DOWNLOAD_CONTRACT_FILE,
  payload,
})
export const getImageBase64Request = (payload) => ({
  type: types.GET_IMAGE_64_REQUEST,
  payload,
})
export const getImageBase64Recieve = (payload) => ({
  type: types.GET_IMAGE_64_RECIEVE,
  payload,
})
