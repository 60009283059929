import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../network/apis/Home';
import * as ACTION from '../actions/Home';
import * as TYPE from '../types/Home';
import { dispatchError, showSnackbar } from '../../utils/Shared';

export function* HomeVacanciesRequest({ payload }) {
  try {
    const response = yield call(API.homeRequest, payload);
    yield put(ACTION.homeVacanciesReceive(response.data));
  } catch (err) {
    dispatchError(err);
  }
}

export function* allowsSharingCandidateData({ payload }) {
  try {
    yield call(API.allowsSharingCandidateData, payload);
    localStorage.setItem('shareStatusDisplayed', true);
    showSnackbar('savedSuccessfuly');
  } catch (err) {
    dispatchError(err);
  }
}

export function* HomeSagasWatch() {
  yield takeLatest(TYPE.ALL_VACANCIES_REQUEST, HomeVacanciesRequest);
  yield takeLatest(TYPE.SHARE_CANDIDATE_REQUEST, allowsSharingCandidateData);
}
