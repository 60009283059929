export default {
  en: {
    Vacancies: "Vacancies",
    home: "Home",
    langBtn: "العربية",
    candidatePortal: "Candidate Portal",
    notifications: "Notifications",
    otp: {
      title: "OTP Verification",
      desc: "A 4-digit OTP has been sent to your email",
      resend: "Resend Code?",
      confirm: "Confirm",
      expire: "The OTP has been Expired",
    },
    Home: {
      Home: "Home",
      visitWebsite: "Visit Website",
      Vacancies: "Vacancies",
      AboutUs: "About us",
      ContactUs: "Contact us",
      Companies: "Companies",
      FindYour: "Find Your",
      DreamJobHere: "Dream Job Here",
      RecommendedJobs: "Recommended jobs",
      Posted: "Posted ",
    },
    Footer: {
      Service: "Service",
      Address: "Adress",
      Contact_Us: "Contact Us",
    },
    errors: {
      fillAllForm: "*Please fill all fields",
      FileMaxSize5MB: "File should not exceed 10 MB",
      pdfOnly: "PDF is only allowed",
      required: "This field is required",
      nameError: "This field is should not be contain a special character",
      validPhone: "Phone is not valid",
      invalidEmail: "Invalid email",
      invalidPassword:
        "Invalid password : Min 8 characters , a mixture of uppercase and lowercase letter and numbers",
      confirmPasswordMatch: "Password and confirm password don’t match",
      imageRatio: "The image ratio must be 1:1",
      invalidGPAValue: "It should be between 0 - ",
      toGreaterThanFrom: "To must be greater than from",
      chooseAnswers: "Please check your answer",
      selctedLanguageBefore: "You are already selected this language before",
      pdfWordFilesAllowed: "PDF, Word are only allowed",
      pdfImagesFilesAllowed: "PDF, Word, JPG, PNG, JPEG are only allowed",
      allowedSize: "File size should not exceed 10MB",
      allowedVideoSize: "The allowed video size is",
      videoAllowedTypes: "mp4 is only allowed",
    },
    shared: {
      done: "Done Successfully",
      savedSuccessfuly: "Your data has been saved successfully",
      deactivatedCompany:
        "This Company is not available now. Please try again later !",
      interview: "Interview",
      logout: "Logout",
      profile: "Profile",
      InterviewEnded: "Interview has Ended!",
      file:'File'
    },
    auth: {
      signup: "Sign up",
      firstName: "First name",
      lastName: "Last name",
      email: "Email",
      forgetPassword: "Forgot password ?",
      successRegister: `A confirmation email has been sent, please check your email. If you didn't receive the confirmation email within 1 minute please press "Send again"`,
      password: "Password",
      confirmPassword: "Confirm password",
      passwordValidRules: "Password is following these rules:",
      passwordValidTipMin: "Min. 8 characters",
      passwordValidTipUpperLower:
        "A mixture of both uppercase and lowercase letters",
      passwordValidTipLetterNumber: "A mixture of letters and numbers",
      signin: "Sign in",
      signupDesign: "Sign up to Easy apply for Jobs",
      addPassword: "Add password",
      ForgetPasswordHeading: "Forget Password",
      Reset: "Reset",
      signupMsg: "Don't have an account? Sign up",
      signinMsg: "Already have an account? Sign in",
      loginSharindDataAgreeMsg:
        "By clicking allow you are allowing us to share your profile with our clients",
      allow: "Allow",
      disallow: "Don't allow",
      sharing: "Allow sharing",
    },
    tabsHeader: {
      personal: "Personal Info",
      advertQuestions: "Advert questions",
      skills: "Skills",
      education: "Education",
      workExperience: "Work Experience",
      reference: "Reference",
      licenses: "Licenses& certifications",
    },
    navBar: {
      myApplications: "My Applications",
      home: "Home",
    },
    buttons: {
      attachPDF: "Upload PDF",
      readMore: "Read More",
      completeProfile: "Complete Profile",
      CVfileType: "Word or PDF are only allowed",
      sendAgain: "Send again",
      register: "Register",
      login: "Login",
      addSection: "Add section",
      addCV: "Application",
      save: "Save",
      cancel: "Cancel",
      drag: "Drag and Drop",
      downloadAttachment: "Download attached file",
      dragCV: "Drag it Here",
      uploadCv: "Upload CV",
      startInterview: "Start interview",
      uploadFile: "Upload file",
      submit: "Submit",
      reset: "Reset",
      uploadImage: "Upload image",
      download: "Download",
      accept: "Accept",
      decline: "Decline",
      sendforreview: "Send for review",
    },
    common: {
      NoDataFound: "No Data Found",
      today: "today",
      days: "Days",
      day: "Day",
      or: "or",
      add: "Add",
      msgSubmit: "You have submitted this form before, Thank you",
    },
    vacancyInner: {
      jobDetailsHeader: "Job details",
      jobLocation: "Job Location",
      apply: "Apply",
      profileErrMsg:
        "Please complete your profile to be able to apply to any vacancy",
      companyBrief: "Company brief",
      completeProfile: "Please Complete your profile",
    },
    educationExperience: {
      college: "College",
      InstituteSchool: "Institute/School",
      degree: "Degree",
      FieldofStudy: "Field of study",
      gpa: "Grade/GPA",
      category: "Category",
      jobTitle: "Job title",
      companyName: "Company name",
      location: "Location",
      from: "From",
      to: "To",
      description: "Description",
      workIndustry: "Work Industry",
      addEducation: "Add education",
      editEducation: "Edit education",
      toPresent: "To present",
      addWorkExperience: "Add work experience",
      editWorkExperience: "Edit work experience",
      present: "Present",
      outOf: "Out of",
      customJobTitle: "Enter job title",
    },
    personalInfo: {
      personalInfoTitle: "Personal information",
      firstName: "First name",
      lastName: "Last name",
      dateOfBirth: "Date of birth",
      residency: "Residence Country",
      city: "Residence City",
      Residency: "Residency",
      gender: "Gender",
      maritalStatus: "Marital status",
      contactDetails: "Contact details",
      phoneNumber: "Phone number",
      personalEmail: "Personal Email",
      address: "Address",
      male: "Male",
      female: "Female",

      nationality: "Nationality",
    },
    skills: {
      skillsInfoTitle: "Skills",
      profLevel: " Proficiency Level",
      engProLevel: "What is your proficiency level in English?",
      languages: "Languages",
      profLevelInEnglish: " Proficiency level in English",
      motherTongue: "Mother tongue",
    },
    licenses: {
      certificateName: "Certificate Name",
      issuingOrganization: "Issuing organization",
      issuedDate: "Issued date",
      expirationDate: "Expiration date",
      noExpirationDate: "This credential does not expire",
    },
    filter: {
      postedDate: "Posted date",
      jobCategory: "Job category",
      contractType: "Contract type",
      filter: "Apply filter",
      reset: "Reset",
      jobTitle: "Job Title",
      country: "Country",
      city: "City",
      search: "Search",
      resetSearch: "Reset",
    },
    screening: {
      screeningHeader: "Please answer the following questions :",
      date: "Date",
      time: "Time",
      time_type: "Type",
      text: "Answer",
    },
    advertQuestionsInfo: {
      advertQuestionsTitle: "Advert questions",
      currentLastSalary: "Current/Last  Salary",
      currency: "Currency",
      currentLastPosition: "Current/Last position",
      currentLastCompany: "Current/Last company name",
      unemployee: "Unemployed",
      employed: "Employed",
      employeeStatus: "Job status",
    },
    snackbar: {
      codeResend: "Code has been Resent to your email",
      successApply: "Thank you for applying, you will hear from us very soon",
      done: "Done Successfully",
      savedSuccessfuly: "Your data has been saved successfully",
      successFormSubmit: "thank you the form was submitted successfully",
      interviewEnded: "Interview has Ended!",
      successApprovedOffer:
        "Thank you for accepting the offer. We are looking forward to start working with you.",
    },
    preoffer: {
      preofferTitle: "Pre-offer",
      hello: "Hello",
      documentsNeededMsg: "The following documents are needed",
      preofferFinishedMsg:
        "Thank you for your time. You will hear from us very soon!",
      preofferFilledBeforeMsg: "Thank you! You have already filled this form",
    },
    moreInfoQuestions: {
      moreInfoRequest: "More information request",
      answersNeededMsg: "The following questions are required to be filled",
      uploadVideo: "Upload video",
      answerTip: "The answer should be in",
      minutes: "minute(s)",
      letters: "letter(s)",
      expirationDate: "Expire in",
      answer: "Answer",
      submittedBefore: "Thank you! You have already filled this form",
      expiredForm: "the form is expired for now",
      requestChangeingDate: "Request changing date",
      requestChangeDateContent:
        "This request end date is original expiration date, but you can request a change if possible , Please select the date & wait for approval",
      newExpirationDate: "New expiration date",
      reason: "Reason",
      previewVideo: "Play Video",
    },
    emailRequests: {
      offerTitle: "Offer",
      contractTitle: "Contract",
      Signed: "Signed :",
      JoiningDate: "Expected joining date :",
      reason: "Reason",
      offerSubmited: "You have submitted this form before, Thank you",
    },
  },
};
