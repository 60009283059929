export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const GET_PROFILE_RECEIVE = "GET_PROFILE_RECEIVE";
export const UPLOAD_CV_REQUEST = "UPLOAD_CV_REQUEST";
export const UPLOAD_CV_RECIEVE = "UPLOAD_CV_RECIEVE";
export const UPLOAD_IMAGE_REQUEST = "UPLOAD_IMAGE_REQUEST";
export const UPLOAD_IMAGE_RECIEVE = "UPLOAD_IMAGE_RECIEVE";
export const SAVE_EDUCATION_INFO_REQUEST = "SAVE_EDUCATION_INFO_REQUEST";
export const DELETE_EDUCATION_INFO_REQUEST = "DELETE_EDUCATION_INFO_REQUEST";
export const SAVE_WORK_EXPERIENCE_INFO_REQUEST =
  "SAVE_WORK_EXPERIENCE_INFO_REQUEST";
export const DELETE_WORK_EXPERIENCE_INFO_REQUEST =
  "DELETE_WORK_EXPERIENCE_INFO_REQUEST";
export const EDIT_PERSONAL_INFO_REQUEST = "EDIT_PERSONAL_INFO_REQUEST";
export const SAVE_SKILLS_REQUEST = "SAVE_SKILLS_REQUEST";
export const SAVE_SIMPLE_PROFILE_REQUEST = "SAVE_SIMPLE_PROFILE_REQUEST";
export const ADD_ADVERT_QUESTIONS_REQUEST = "ADD_ADVERT_QUESTIONS_REQUEST";
export const DISABLE_ADVERT_QUESTIONS_REQUEST =
  "DISABLE_ADVERT_QUESTIONS_REQUEST";
export const DISABLE_ADVERT_QUESTIONS_RECEIVE =
  "DISABLE_ADVERT_QUESTIONS_RECEIVE";
export const SAVE_LICENSES_CERTIFICATES = "SAVE_LICENSES_CERTIFICATES";
export const DELETE_LICENSES_CERTIFICATES_REQUEST = "DELETE_LICENSES_CERTIFICATES_REQUEST";
export const CANDIDATE_COMPANY_REQUEST = "CANDIDATE_COMPANY_REQUEST"
export const CANDIDATE_COMPANY_RECIEVE = "CANDIDATE_COMPANY_RECIEVE"