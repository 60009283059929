import React from "react";
import Auth from "../utils/Auth";
import { Route, Redirect } from "react-router-dom";
import { authRedirection } from "./redirection";

export const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const isAuthenticated = Auth.getAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          path.split("/")[1] === "resetPassword" ||
          path.split("/")[1] === "forgetPassword" ? (
            <Redirect to="/" />
          ) : (
            <Component {...props} />
          )
        ) : path.split("/")[1] === "resetPassword" ||
          path.split("/")[1] === "forgetPassword" ? (
          <Component {...props} />
        ) : (
          (window.location.href = authRedirection("login"))
        )
      }
    />
  );
};
