import * as types from "../types/Profile";

export const userProfileRequest = () => ({
  type: types.GET_PROFILE_REQUEST,
});
export const userProfileReceive = (payload) => ({
  type: types.GET_PROFILE_RECEIVE,
  payload,
});

export const uploadCvRequest = (payload) => ({
  type: types.UPLOAD_CV_REQUEST,
  payload,
});
export const uploadCvReceive = (payload) => ({
  type: types.UPLOAD_CV_RECIEVE,
  payload,
});

export const uploadImageRequest = (payload) => ({
  type: types.UPLOAD_IMAGE_REQUEST,
  payload,
});
export const uploadImageReceive = (payload) => ({
  type: types.UPLOAD_IMAGE_RECIEVE,
  payload,
});
export const saveEducationInfoDetailsRequest = (payload) => ({
  type: types.SAVE_EDUCATION_INFO_REQUEST,
  payload,
});
export const deleteEducationInfoDetailsRequest = (payload) => ({
  type: types.DELETE_EDUCATION_INFO_REQUEST,
  payload,
});

export const saveWorkExperienceInfoDetailsRequest = (payload) => ({
  type: types.SAVE_WORK_EXPERIENCE_INFO_REQUEST,
  payload,
});
export const deleteWorkExperienceInfoDetailsRequest = (payload) => ({
  type: types.DELETE_WORK_EXPERIENCE_INFO_REQUEST,
  payload,
});

export const editPersonalInfoRequest = (payload) => ({
  type: types.EDIT_PERSONAL_INFO_REQUEST,
  payload,
});

export const saveSkillsRequest = (payload) => ({
  type: types.SAVE_SKILLS_REQUEST,
  payload,
});

export const simpleProfileRequest = (payload) => ({
  type: types.SAVE_SIMPLE_PROFILE_REQUEST,
  payload,
});

export const addAdbvertQuestionsRequest = (payload) => ({
  type: types.ADD_ADVERT_QUESTIONS_REQUEST,
  payload,
});
export const disableAdvertQuestionsRequest = () => ({
  type: types.DISABLE_ADVERT_QUESTIONS_REQUEST,
});
export const disableAdvertQuestionsReceive = (payload) => ({
  type: types.DISABLE_ADVERT_QUESTIONS_RECEIVE,
  payload,
});

export const saveLicensesCertificatesRequest = (payload) => ({
  type: types.SAVE_LICENSES_CERTIFICATES,
  payload,
});

export const deleteLicensesCertificatesRequest = (payload) => ({
  type: types.DELETE_LICENSES_CERTIFICATES_REQUEST,
  payload,
});
export const candidateCompanyRequest = (payload) => ({
  type: types.CANDIDATE_COMPANY_REQUEST,
  payload,
});
export const candidateCompanyRecieve = (payload) => ({
  type: types.CANDIDATE_COMPANY_RECIEVE,
  payload,
});
