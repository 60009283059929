import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../network/apis/UploadCVMode";
import * as ACTIONS from "../actions/UploadCVMode";
import * as TYPE from "../types/UploadCVMode";
import { dispatchError, showSnackbar } from "../../utils/Shared";
import History from "../../routes/History";

export function* UploadCVMode({ payload }) {
  try {
    const response = yield call(API.uploadCVModeRequest, payload);
    localStorage.setItem("dummy_id", response.data.user_id);
    showSnackbar("done");
    yield put(ACTIONS.uploadCvModeReceive(true));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* SendOTP({ payload }) {
  try {
    yield call(API.OTPValidation, payload);
    yield showSnackbar("done");
    yield put(ACTIONS.uploadCvModeReceive(false));
    History.push("/");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* ResendOTP({ payload }) {
  yield put(ACTIONS.verifyOTPRecieve(false));
  try {
    yield call(API.ResendOTPValidation, payload);
    yield showSnackbar("codeResend");
    yield put(ACTIONS.timerOTPExpire(false));
    yield put(ACTIONS.verifyOTPRecieve(true));
  } catch (err) {
    yield put(ACTIONS.timerOTPExpire(false));
    yield put(ACTIONS.verifyOTPRecieve(false));
    dispatchError(err.response?.data);
  }
}

export function* UploadCVModeSagasWatch() {
  yield takeLatest(TYPE.UPLOAD_CV_MODE_REQUEST, UploadCVMode);
  yield takeLatest(TYPE.VERIFY_OTP_CODE_REQUEST, SendOTP);
  yield takeLatest(TYPE.RESEND_OTP_CODE_REQUEST, ResendOTP);
}
