import HRCOMLogoNavbar from "../assets/images/0-01.png";
import HRCOMLogoWhite from "../assets/images/ic_logo-footer.svg";
import HRCOMLogoFooter from "../assets/images/0-03.png";
import HRCOMLogoAuth from "../assets/images/0-06.png";
import HRCOMLogoNoWord from "../assets/images/logo-no-word.png";
import LOGO_HOME_HEADER from "../assets/images/imgHero.png";


export const candidatePortalLogo = (props) => {
    const jobSeekerUrl = process.env.REACT_APP_JOB_SEEKER_URL;
  const {
    website_url,
    plan_features: { has_linking_your_website },
  } = props;
  const expire = props.company_subscription_details.current_plan_end_date;
  let disableLink = "";
  let url = jobSeekerUrl;

  if (has_linking_your_website) {
    url = website_url && website_url !== "" ? `${website_url}` : jobSeekerUrl;
  }
  if (expireAfter3Days(expire)) {
    url = jobSeekerUrl;
  }
  return {
    url,
    disableLink,
  };
};
export const colorsWithPlan = ({
  primary_color,
  secondary_color,
  candidate_home_image,
  plan_features: { has_brand_colors },
  company_subscription_details: { current_plan_end_date },
}) => {
  if (expireAfter3Days(current_plan_end_date)) {
    return {
      ...defaultSetupColors_Logos.colors,
      candidate_home_image: LOGO_HOME_HEADER,
    };
  } else if (has_brand_colors) {
    return {
      primary_color: primary_color ? primary_color : "#86364e",
      secondary_color: secondary_color ? secondary_color : "#ea8685",
      candidate_home_image: candidate_home_image,
    };
  } else if (!has_brand_colors) {
    return {
      ...defaultSetupColors_Logos.colors,
      candidate_home_image: LOGO_HOME_HEADER,
    };
  }
};

export const footerWithPlan = ({
  primary_color,
  secondary_color,
  plan_features: { has_brand_colors },
}) => {
  if (
    has_brand_colors &&
    primary_color === "#86364e" &&
    secondary_color === "#ea8685"
  ) {
    return {
      logo_footer: HRCOMLogoFooter,
    };
  }
  if (
    has_brand_colors &&
    (primary_color !== "#86364e" || secondary_color !== "#ea8685")
  ) {
    return {
      logo_footer: HRCOMLogoWhite,
    };
  }

  if (!has_brand_colors) {
    return {
      logo_footer: HRCOMLogoFooter,
    };
  }
};

export const companyBriefWithPlan = ({
  plan_features: { has_company_brief },
  company_subscription_details: { current_plan_end_date },
}) => {
  if (has_company_brief && !expireAfter3Days(current_plan_end_date)) {
    return {
      hide: false,
    };
  }
  if (!has_company_brief || expireAfter3Days(current_plan_end_date)) {
    return {
      hide: true,
    };
  }
};

export const logoWithPlan = ({
  company_logo_url,
  secondary_color,
  primary_color,
  company_subscription_details,
  plan_features: { has_brand_logo, has_brand_colors },
}) => {
  const expire = company_subscription_details.current_plan_end_date;
  if (expireAfter3Days(expire)) {
    return defaultSetupColors_Logos.logos;
  }
  if (has_brand_logo) {
    return {
      logo_navbar: company_logo_url,
      logo_auth: company_logo_url,
    };
  }
  if (
    has_brand_colors &&
    (primary_color !== "#86364e" || secondary_color !== "#ea8685")
  ) {
    return {
      logo_navbar: HRCOMLogoNoWord,
      logo_auth: HRCOMLogoWhite,
    };
  }
  if (primary_color === "#86364e" && secondary_color === "#ea8685") {
    return defaultSetupColors_Logos.logos;
  }
};

export const expireAfter3Days = (date) => {
  const endDate = new Date(date);
  endDate.setDate(endDate.getDate() + 3);
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);
  return date === null ? false : today > endDate;
};

export const defaultSetupColors_Logos = {
  colors: {
    primary_color: "#86364e",
    secondary_color: "#ea8685",
  },
  logos: {
    logo_navbar: HRCOMLogoNavbar,
    logo_auth: HRCOMLogoAuth,
  },
};
