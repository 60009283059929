import Cookies from 'js-cookie';
import { getEnv } from './redirection';

const Auth = {
  signOut() {
    Cookies.remove('userData', { domain: getEnv() });
    Cookies.remove('token', { domain: getEnv() });
    localStorage.removeItem('registerationUserId');
    localStorage.removeItem('showApply');
    localStorage.removeItem('currentTab');
  },
  getAuth() {
    return (
      Cookies.get('userData', { domain: getEnv() }) &&
      Cookies.get('token', { domain: getEnv() })
    );
  },
  getProfileSharingStatus() {
    var userData = Cookies.get('userData', { domain: getEnv() });
    if (userData) return JSON.parse(userData).is_allowed_profile_share;
    return false;
  },
};
export default Auth;
