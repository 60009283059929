import * as types from "../types/Offer";
import { GET_IMAGE_64_RECIEVE } from "../types/Contract";

const INITIAL_STATE = {
  offer_data: {},
  signature_image: null,
  _CoverLetter: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_OFFER_TEMPLATE_RECEIVE:
      return { ...state, offer_data: action.payload };
    case GET_IMAGE_64_RECIEVE:
      return { ...state, _CoverLetter: action.payload };
    case types.UPLOAD_CANDIDATE_SIGNATURE_RECEIVE:
      return { ...state, signature_image: action.payload };
    default:
      return state;
  }
};
