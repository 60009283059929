export const COLLEGE_LOOKUP_REQUEST = "COLLEGE_LOOKUP_REQUEST";
export const COLLEGE_LOOKUP_RECEIVE = "COLLEGE_LOOKUP_RECEIVE";

export const DEGREE_LOOKUP_REQUEST = "DEGREE_LOOKUP_REQUEST";
export const DEGREE_LOOKUP_RECEIVE = "DEGREE_LOOKUP_RECEIVE";

export const JOB_TITLE_LOOKUP_REQUEST = "JOB_TITLE_LOOKUP_REQUEST";
export const JOB_TITLE_LOOKUP_RECEIVE = "JOB_TITLE_LOOKUP_RECEIVE";

export const JOB_TITLE_FILTER_CATEGORY_LOOKUP_REQUEST =
  "JOB_TITLE_FILTER_CATEGORY_LOOKUP_REQUEST";
export const JOB_TITLE_FILTER_CATEGORY_LOOKUP_RECEIVE =
  "JOB_TITLE_FILTER_CATEGORY_LOOKUP_RECEIVE";

export const JOB_TITLE_CATEGORY_LOOKUP_REQUEST =
  "JOB_TITLE_CATEGORY_LOOKUP_REQUEST";
export const JOB_TITLE_CATEGORY_LOOKUP_RECEIVE =
  "JOB_TITLE_CATEGORY_LOOKUP_RECEIVE";

export const RESIDENCIES_LOOKUP_REQUEST = "RESIDENCIES_LOOKUP_REQUEST";
export const RESIDENCIES_LOOKUP_RECEIVE = "RESIDENCIES_LOOKUP_RECEIVE";

export const LANGUAGES_WITHOUT_ENGLISH_LOOKUP_REQUEST =
  "LANGUAGES_WITHOUT_ENGLISH_LOOKUP_REQUEST";
export const LANGUAGES_WITHOUT_ENGLISH_LOOKUP_RECEIVE =
  "LANGUAGES_WITHOUT_ENGLISH_LOOKUP_RECEIVE";
export const JOB_CATEGORY_LOOKUP_REQUEST = "JOB_CATEGORY_LOOKUP_REQUEST";
export const JOB_CATEGORY_LOOKUP_RECEIVE = "JOB_CATEGORY_LOOKUP_RECEIVE";

export const JOB_CONTRACT_TYPE_LOOKUP_REQUEST =
  "JOB_CONTRACT_TYPE_LOOKUP_REQUEST";
export const JOB_CONTRACT_TYPE_LOOKUP_RECEIVE =
  "JOB_CONTRACT_TYPE_LOOKUP_RECEIVE";

export const COUNTRIES_LOOKUP_REQUEST = "COUNTRIES_LOOKUP_REQUEST";
export const COUNTRIES_LOOKUP_RECEIVE = "COUNTRIES_LOOKUP_RECEIVE";

export const CITIES_LOOKUP_REQUEST = "CITIES_LOOKUP_REQUEST";
export const CITIES_LOOKUP_RECEIVE = "CITIES_LOOKUP_RECEIVE";

export const LOOKUP_CURRENCY_REQUEST = "LOOKUP_CURRENCY_REQUEST";
export const LOOKUP_CURRENCY_RECEIVE = "LOOKUP_CURRENCY_RECEIVE";

export const SKILLS_LOOKUP_REQUEST = "SKILLS_LOOKUP_REQUEST";
export const SKILLS_LOOKUP_RECIEVE = "SKILLS_LOOKUP_RECIEVE";

export const USER_NATIONALITY_REQUEST = "USER_NATIONALITY_REQUEST";
export const USER_NATIONALITY_RECIEVE = "USER_NATIONALITY_RECIEVE";

export const JOB_TITLE_PUBLISHED_LOOKUP_REQUEST =
  "JOB_TITLE_PUBLISHED_LOOKUP_REQUEST";
export const JOB_TITLE_PUBLISHED_LOOKUP_RECIEVE =
  "JOB_TITLE_PUBLISHED_LOOKUP_RECIEVE";
