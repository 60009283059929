import * as types from "../types/MoreInformationQuestions";

export const requestGetMoreInfoQuestions = (payload) => {
  return {
    type: types.REQUEST_GET_MORE_INFO_QUESTIONS,
    payload,
  };
};
export const receiveGetMoreInfoQuestions = (payload) => ({
  type: types.RECEIVE_GET_MORE_INFO_QUESTIONS,
  payload,
});

export const requestSaveMoreInfoQuestionsText = (payload) => ({
  type: types.REQUEST_SAVE_MORE_INFO_QUESTIONS_TEXT,
  payload,
});
export const receiveSaveMoreInfoQuestionsText = (payload) => ({
  type: types.RECEIVE_SAVE_MORE_INFO_QUESTIONS_TEXT,
  payload,
});

export const requestSaveMoreInfoQuestionsVideo = (payload) => ({
  type: types.REQUEST_SAVE_MORE_INFO_QUESTIONS_VIDEO,
  payload,
});
export const receiveSaveMoreInfoQuestionsVideo = (payload) => ({
  type: types.RECEIVE_SAVE_MORE_INFO_QUESTIONS_VIDEO,
  payload,
});

export const requestChangeExpirationDate = (payload) => ({
  type: types.REQUEST_CHANGE_EXPIRATION_DATE,
  payload,
});
export const receiveChangeExpirationDate = (payload) => ({
  type: types.RECEIVE_CHANGE_EXPIRATION_DATE,
  payload,
});
