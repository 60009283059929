import { axiosInstance } from './index';
import qs from 'qs';
let handlerEnabled = true;

const homeRequest = async (params) => {
  return await axiosInstance.get(`/candidate_job_request/get`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    handlerEnabled,
  });
};
const allowsSharingCandidateData = async (data) => {
  return await axiosInstance.put('/candidate/profile/set_allow_share', {
    is_allowed_profile_share: data,
  });
};

export default {
  homeRequest,
  allowsSharingCandidateData,
};
