import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../network/apis/Lookups";
import * as TYPE from "../types/Lookups";
import * as ACTION from "../actions/Lookups";

export function* collegeLookupRequest() {
  try {
    const response = yield call(API.collegeLookupRequest);
    yield put(ACTION.collegeLookupReceive(response.data));
  } catch (err) {
    console.log(err);
  }
}
export function* degreeLookupRequest() {
  try {
    const response = yield call(API.degreeLookupRequest);
    yield put(ACTION.degreeLookupReceive(response.data));
  } catch (err) {
    console.log(err);
  }
}
export function* jobTitleLookupRequest() {
  try {
    const response = yield call(API.jobTitleLookupRequest);
    yield put(ACTION.jobTitleLookupReceive(response.data.data));
  } catch (err) {
    console.log(err);
  }
}
export function* publishedjobTitleLookupRequest() {
  try {
    const response = yield call(API.publishedJobTitleLookupRequest);
    yield put(ACTION.jobTitleLookupReceive(response.data.data));
  } catch (err) {
    console.log(err);
  }
}
export function* jobTitleFilterCategoryLookupRequest({ payload }) {
  try {
    const response = yield call(
      API.jobTitleFilterCategoryLookupRequest,
      payload
    );
    yield put(ACTION.jobTitleFilterCategoryLookupReceive(response.data.data));
  } catch (err) {
    console.log(err);
  }
}
export function* jobTitleCategoryLookupRequest() {
  try {
    const response = yield call(API.jobTitleCategoryLookupRequest);
    yield put(ACTION.jobTitleCategoryLookupReceive(response.data.data));
  } catch (err) {
    console.log(err);
  }
}

export function* residenciesLookupRequest() {
  try {
    const response = yield call(API.residenciesLookupRequest);
    yield put(ACTION.residenciesLookupReceive(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* languagesWithOutEnglishLookupRequest() {
  try {
    const response = yield call(API.languagesWithOutEnglish);
    yield put(ACTION.languagesWithOutEnglishLookupReceive(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* jobCategoriesLookupRequest() {
  try {
    const response = yield call(API.jobCategoryLookupRequest);
    yield put(ACTION.jobCategoryLookupReceive(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* jobContractTypeLookupRequest() {
  try {
    const response = yield call(API.jobContractTypeLookupRequest);
    yield put(ACTION.jobContractTypeLookupReceive(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* countriesLookupRequest() {
  try {
    const response = yield call(API.countriesLookupsRequest);
    yield put(ACTION.countriesLookupReceive(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* citiesLookupRequest({ payload }) {
  try {
    const response = yield call(API.citiesLookupsRequest, payload);
    yield put(ACTION.citiesLookupReceive(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* skillsLookupRequest() {
  try {
    const response = yield call(API.skillsLookupsRequest);
    yield put(ACTION.skillsLookupReceive(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* lookupCurrencies() {
  try {
    const response = yield call(API.currencyLookupRequest);
    yield put(ACTION.receiveCurrency(response.data));
  } catch (error) {
    console.log(error);
  }
}

export function* getUserNationality() {
  try {
    const response = yield call(API.getUserNationality);
    yield put(ACTION.userNationalityReceive(response.data));
  } catch (error) {
    console.log(error);
  }
}

export function* LookupsSagasWatch() {
  yield takeLatest(TYPE.LOOKUP_CURRENCY_REQUEST, lookupCurrencies);
  yield takeLatest(TYPE.COLLEGE_LOOKUP_REQUEST, collegeLookupRequest);
  yield takeLatest(TYPE.DEGREE_LOOKUP_REQUEST, degreeLookupRequest);
  yield takeLatest(TYPE.JOB_TITLE_LOOKUP_REQUEST, jobTitleLookupRequest);
  yield takeLatest(TYPE.JOB_TITLE_PUBLISHED_LOOKUP_REQUEST, publishedjobTitleLookupRequest);
  yield takeLatest(
    TYPE.JOB_TITLE_FILTER_CATEGORY_LOOKUP_REQUEST,
    jobTitleFilterCategoryLookupRequest
  );
  yield takeLatest(
    TYPE.JOB_TITLE_CATEGORY_LOOKUP_REQUEST,
    jobTitleCategoryLookupRequest
  );
  yield takeLatest(TYPE.RESIDENCIES_LOOKUP_REQUEST, residenciesLookupRequest);
  yield takeLatest(
    TYPE.LANGUAGES_WITHOUT_ENGLISH_LOOKUP_REQUEST,
    languagesWithOutEnglishLookupRequest
  );
  yield takeLatest(
    TYPE.JOB_CATEGORY_LOOKUP_REQUEST,
    jobCategoriesLookupRequest
  );
  yield takeLatest(
    TYPE.JOB_CONTRACT_TYPE_LOOKUP_REQUEST,
    jobContractTypeLookupRequest
  );
  yield takeLatest(TYPE.COUNTRIES_LOOKUP_REQUEST, countriesLookupRequest);
  yield takeLatest(TYPE.CITIES_LOOKUP_REQUEST, citiesLookupRequest);
  yield takeLatest(TYPE.SKILLS_LOOKUP_REQUEST, skillsLookupRequest);

  yield takeLatest(TYPE.USER_NATIONALITY_REQUEST, getUserNationality);
}
