import store from "../store";
import moment from "moment-timezone";
import { showSnackbarAction } from "../store/actions/Snackbar";
import messages from "../assets/Local/messages";
import * as jwt_decode from "jwt-decode";
import History from "./../routes/History";
import Cookies from "js-cookie";
import { getEnv } from "./redirection";
export const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();

export function showSnackbar(message) {
  const lang = store.getState().locale.lang;
  store.dispatch(
    showSnackbarAction(
      typeof message !== "string"
        ? message["val"]
        : messages[lang].snackbar[message],
      "success"
    )
  );
}

export function dispatchError(data, message) {
  const errorMsg = data
    ? data.error
      ? data.error.message
      : data.Error.Message
    : message;
  store.dispatch(showSnackbarAction(errorMsg, "error"));
}

export function getDecodedAccessToken(token) {
  try {
    return jwt_decode(token);
  } catch (Error) {
    return null;
  }
}

export function pushToUrlNewParam(page) {
  History.push({
    search: `?page=${page}`,
  });
}

export const getYesterdayDate = () => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday;
};

export const updateTokenFromUrl = () => {
  const token = getURLParams("token");
  Cookies.set("token", token, { expires: 1, domain: getEnv() });
};

export function getURLParams(paramName) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(paramName);
}

export function parseString(data) {
  if (data) return JSON.parse(data);
  else {
    return null;
  }
}
export const validPhoneNumber = (number, code) => {
  try {
    return !phoneUtil.isValidNumberForRegion(
      phoneUtil.parse(number, code),
      code
    );
  } catch (error) {
    return true;
  }
};
export const isToday = (pastDate) => {
  const formatedGiven = moment.utc(pastDate).local().format("YYYY-MM-DD");
  const given = moment(formatedGiven, "YYYY-MM-DD");
  const current = moment().startOf("day");
  return moment.duration(given.diff(current)).asDays() === 0 ? true : false;
};
