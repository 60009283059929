import { axiosInstance } from "./index";

let handlerEnabled = true;

const startInterviewRequest = async (id) => {
  return await axiosInstance.get(
    `/candidate/application/interview/start/${id}`,
    { handlerEnabled }
  );
};
const interviewDurationRequest = async ({ id, min }) => {
  let params = {
    consumed_minutes: min,
  };
  return await axiosInstance.put(
    `/admin/agent_interview/consumed_Minutes_InEndInterview/${id}`,
    {},
    { params, handlerEnabled }
  );
};

const leaveInterviewRequest = async (data) => {
  return await axiosInstance.post(`/admin/agent_interview/leave`, data, {
    handlerEnabled,
  });
};

export default {
  startInterviewRequest,
  interviewDurationRequest,
  leaveInterviewRequest
};

