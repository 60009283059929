import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../network/apis/MyApplications";
import * as ACTIONS from "../actions/MyApplications";
import * as TYPE from "../types/MyApplications";
import { dispatchError } from "../../utils/Shared";
import ReactGA from "react-ga";

export function* myApplicationsRequest({ payload }) {
  try {
    const response = yield call(API.myApplicationsRequest, payload);
    yield put(ACTIONS.myApplicationsReceive(response.data));
    ReactGA.event({
      category: "My application",
      action: "User pressed my applications button",
      label: localStorage.getItem("domain"),
    });
  } catch (err) {
    dispatchError(err.response.data);
  }
}

export function* MyApplicationsSagasWatch() {
  yield takeLatest(TYPE.MY_APPLICATIONS_REQUSET, myApplicationsRequest);
}
