import * as types from "../types/Lookups";

export const collegeLookupRequest = () => ({
  type: types.COLLEGE_LOOKUP_REQUEST,
});
export const collegeLookupReceive = (payload) => ({
  type: types.COLLEGE_LOOKUP_RECEIVE,
  payload,
});

export const degreeLookupRequest = () => ({
  type: types.DEGREE_LOOKUP_REQUEST,
});
export const degreeLookupReceive = (payload) => ({
  type: types.DEGREE_LOOKUP_RECEIVE,
  payload,
});
export const jobTitleLookupRequest = () => ({
  type: types.JOB_TITLE_LOOKUP_REQUEST,
});
export const jobTitleLookupReceive = (payload) => ({
  type: types.JOB_TITLE_LOOKUP_RECEIVE,
  payload,
});
export const publishedJobTitleLookupRequest = () => ({
  type: types.JOB_TITLE_PUBLISHED_LOOKUP_REQUEST,
});
export const publishedJobTitleLookupRecieve = (payload) => ({
  type: types.JOB_TITLE_PUBLISHED_LOOKUP_RECIEVE,
  payload,
});
export const jobTitleFilterCategoryLookupRequest = (payload) => ({
  type: types.JOB_TITLE_FILTER_CATEGORY_LOOKUP_REQUEST,
  payload,
});
export const jobTitleFilterCategoryLookupReceive = (payload) => ({
  type: types.JOB_TITLE_FILTER_CATEGORY_LOOKUP_RECEIVE,
  payload,
});
export const jobTitleCategoryLookupRequest = () => ({
  type: types.JOB_TITLE_CATEGORY_LOOKUP_REQUEST,
});
export const jobTitleCategoryLookupReceive = (payload) => ({
  type: types.JOB_TITLE_CATEGORY_LOOKUP_RECEIVE,
  payload,
});

export const languagesWithOutEnglishLookupRequest = () => ({
  type: types.LANGUAGES_WITHOUT_ENGLISH_LOOKUP_REQUEST,
});
export const languagesWithOutEnglishLookupReceive = (payload) => ({
  type: types.LANGUAGES_WITHOUT_ENGLISH_LOOKUP_RECEIVE,
  payload,
});

export const residenciesLookupRequest = () => ({
  type: types.RESIDENCIES_LOOKUP_REQUEST,
});
export const residenciesLookupReceive = (payload) => ({
  type: types.RESIDENCIES_LOOKUP_RECEIVE,
  payload,
});

export const jobCategoryLookupRequest = () => ({
  type: types.JOB_CATEGORY_LOOKUP_REQUEST,
});
export const jobCategoryLookupReceive = (payload) => ({
  type: types.JOB_CATEGORY_LOOKUP_RECEIVE,
  payload,
});

export const jobContractTypeLookupRequest = () => ({
  type: types.JOB_CONTRACT_TYPE_LOOKUP_REQUEST,
});
export const jobContractTypeLookupReceive = (payload) => ({
  type: types.JOB_CONTRACT_TYPE_LOOKUP_RECEIVE,
  payload,
});

export const countriesLookupRequest = () => ({
  type: types.COUNTRIES_LOOKUP_REQUEST,
});
export const countriesLookupReceive = (payload) => ({
  type: types.COUNTRIES_LOOKUP_RECEIVE,
  payload,
});

export const citiesLookupRequest = (payload) => ({
  type: types.CITIES_LOOKUP_REQUEST,
  payload,
});
export const citiesLookupReceive = (payload) => ({
  type: types.CITIES_LOOKUP_RECEIVE,
  payload,
});

export const skillsLookupRequest = (payload) => ({
  type: types.SKILLS_LOOKUP_REQUEST,
  payload,
});
export const skillsLookupReceive = (payload) => ({
  type: types.SKILLS_LOOKUP_RECIEVE,
  payload,
});

export const requestCurrency = () => ({
  type: types.LOOKUP_CURRENCY_REQUEST,
});

export const receiveCurrency = (payload) => ({
  type: types.LOOKUP_CURRENCY_RECEIVE,
  payload,
});

export const userNationalityRequest = () => ({
  type: types.USER_NATIONALITY_REQUEST,
});
export const userNationalityReceive = (payload) => ({
  type: types.USER_NATIONALITY_RECIEVE,
  payload,
});
