export const REQUEST_GET_MORE_INFO_QUESTIONS = 'REQUEST_GET_MORE_INFO_QUESTIONS';
export const RECEIVE_GET_MORE_INFO_QUESTIONS = 'RECEIVE_GET_MORE_INFO_QUESTIONS';

export const REQUEST_SAVE_MORE_INFO_QUESTIONS_TEXT = 'REQUEST_SAVE_MORE_INFO_QUESTIONS_TEXT';
export const RECEIVE_SAVE_MORE_INFO_QUESTIONS_TEXT = 'RECEIVE_SAVE_MORE_INFO_QUESTIONS_TEXT';

export const REQUEST_SAVE_MORE_INFO_QUESTIONS_VIDEO = 'REQUEST_SAVE_MORE_INFO_QUESTIONS_VIDEO';
export const RECEIVE_SAVE_MORE_INFO_QUESTIONS_VIDEO = 'RECEIVE_SAVE_MORE_INFO_QUESTIONS_VIDEO';

export const REQUEST_CHANGE_EXPIRATION_DATE = 'REQUEST_CHANGE_EXPIRATION_DATE';
export const RECEIVE_CHANGE_EXPIRATION_DATE = 'RECEIVE_CHANGE_EXPIRATION_DATE';