import * as types from "../types/Lookups";

const INITIAL_STATE = {
  languagesWithoutEnglish: [],
  college: [],
  degree: [],
  jobTitle: [],
  jobTitleCategory: [],
  residencies: [],
  jobCategory: [],
  jobContractType: [],
  countries: [],
  cities: [],
  skills: [],
  currencies: [],
  nationalities: [],
  publishedJobTitles: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.COLLEGE_LOOKUP_RECEIVE:
      return { ...state, college: action.payload };
    case types.DEGREE_LOOKUP_RECEIVE:
      return { ...state, degree: action.payload };
    case types.JOB_TITLE_LOOKUP_RECEIVE:
      return { ...state, jobTitle: action.payload };
    case types.JOB_TITLE_FILTER_CATEGORY_LOOKUP_RECEIVE:
      return { ...state, jobTitle: action.payload };
    case types.JOB_TITLE_CATEGORY_LOOKUP_RECEIVE:
      return { ...state, jobTitleCategory: action.payload };
    case types.RESIDENCIES_LOOKUP_RECEIVE:
      return { ...state, residencies: action.payload };
    case types.LANGUAGES_WITHOUT_ENGLISH_LOOKUP_RECEIVE:
      return { ...state, languagesWithoutEnglish: action.payload };
    case types.JOB_CATEGORY_LOOKUP_RECEIVE:
      return { ...state, jobCategory: action.payload };
    case types.LOOKUP_CURRENCY_RECEIVE:
      return { ...state, currencies: [...action.payload] };
    case types.JOB_CONTRACT_TYPE_LOOKUP_RECEIVE:
      return { ...state, jobContractType: action.payload };
    case types.COUNTRIES_LOOKUP_RECEIVE:
      return { ...state, countries: action.payload };
    case types.CITIES_LOOKUP_RECEIVE:
      return { ...state, cities: action.payload };
    case types.SKILLS_LOOKUP_RECIEVE:
      return { ...state, skills: action.payload };
    case types.JOB_TITLE_PUBLISHED_LOOKUP_RECIEVE:
      return { ...state, publishedJobTitles: action.payload };
    case types.USER_NATIONALITY_RECIEVE:
      return {
        ...state,
        nationalities: action.payload,
      };
    default:
      return state;
  }
};
